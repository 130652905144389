import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player/lazy';

const Trailer = (props) => {
    const { videos } = props;

    return (
        <div className="trailer">
            <div className="trailerDesktop">
                <div className='player-wrapper'>
                    {videos.videos.results[0] ? <ReactPlayer url={videos.videos.results[videos.videos.results.length - 1].site == "YouTube" ? `https://www.youtube.com/watch?v=${videos.videos.results[videos.videos.results.length - 1].key}` : videos.videos.results[videos.videos.results.length - 1].site == "Vimeo" ? `https://vimeo.com/${videos.videos.results[videos.videos.results.length - 1].key}` : null} className='react-player' controls width='100%' height='100%' /> : <div className="image"><img src={`https://image.tmdb.org/t/p/original${videos.backdrop_path}`} />
                        {/* {videos.videos.results[0] ? <iframe width="420" height="280" src={videos.videos.results[videos.videos.results.length - 1].site == "YouTube" ? `https://www.youtube.com/embed/${videos.videos.results[videos.videos.results.length - 1].key}` : videos.videos.results[videos.videos.results.length - 1].site == "Vimeo" ? `https://vimeo.com/${videos.videos.results[videos.videos.results.length - 1].key}` : null} title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> : <div className="image"><img src={`https://image.tmdb.org/t/p/original${videos.backdrop_path}`} /> */}
                        <h2>Trailer unavailable</h2></div>}
                </div>
            </div>
            <div className="conteneur">
                {videos.title || videos.name ?
                    <li>{videos.title ? videos.title : videos.name}</li> : null
                }
                {videos.runtime ? (<><li>
                    <span> Duration :  </span>
                    <span> {Math.floor(videos.runtime / 60)}h {videos.runtime % 60}m</span>
                </li></>) : (<><li>
                    <span> Nb of seasons :  </span>
                    <span> {videos.number_of_seasons}</span>
                </li></>)}
            </div>
        </div>
    )
}

export default Trailer;